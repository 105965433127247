import {
  catalogs as baseCatalogs,
  defaultLocale,
} from '@bottlebooks/gatsby-theme-event/src/wrapElement/lingui/i18n.config';
import { i18n } from '@lingui/core';
import { messages as en } from '../../../../locales/en';

const catalogs = {
  ...baseCatalogs,
  en: {
    messages: { ...baseCatalogs.en.messages, ...en },
  },
};

export { locales } from '@bottlebooks/gatsby-theme-event/src/wrapElement/lingui/i18n.config';
export { defaultLocale };

export function load(locale) {
  if (!locale) return {};
  const catalog = catalogs[locale];
  i18n.load(locale, catalog.messages);
  return catalog.messages;
}
